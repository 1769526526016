import * as yup from 'yup';

export const SUPPORTED_FORMATS = [
  'image/jpeg',
  'image/jpg',
  'image/png',
];

export const phoneRegExp = /(84|0[3|5|7|8|9])+([0-9]{8})\b/;

export const schemaSiteCheck = yup.object({
  project_code: yup.object().nullable().required('Mã dự án là bắt buộc'),
  name: yup.string().required('Tên là bắt buộc'),
  phone: yup.string().required('Số điện thoại là bắt buộc').matches(phoneRegExp, 'Số điện thoại  không đúng định dạng!'),
});

export const schemaOutletInfo = yup.object({
  province: yup.object().nullable().required('Tỉnh/Thành Phố là bắt buộc'),
  district: yup.object().nullable().required('Quận/Huyện là bắt buộc'),
  wards: yup.object().nullable().required('Xã/Phường là bắt buộc'),
  address: yup.string().required('Địa chỉ là bắt buộc'),
  phone: yup.string().test('', 'Số điện thoại  không đúng định dạng!', (value) => {
    if (value && !phoneRegExp.test(value)) return false;
    return true;
  }),
  statusWork: yup.number(),
  denyNote: yup.string().when('statusWork', {
    is: 3,
    then: yup
      .string()
      .required('Lý do từ chối là bắt buộc!')
  }),
  hasImage: yup.boolean(),
  listThumbnail: yup
    .array()
    .when('hasImages', {
      is: false,
      then: yup
        .array()
        .min(3, 'Tối thiểu từ 3 hình ảnh')
    }),
});
