export const DEFAULT_QUERY_OPTION = {
  retry: 0,
  refetchOnMount: true,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
};

export const placeholder = '';
export const YEARS_OFFSET = 100;
export const IMAGE_SIZE_1MB = 1000000;

export const URL_CONST = {
  MEDIA_FILE_UPLOAD_CHUNK: 'api/v1/images/chunk-image',
};
