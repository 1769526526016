import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';

import Text from 'components/atoms/Text';
import Carousel, { NextArrow, PrevArrow } from 'components/organisms/Carousel';
import CarouselModal from 'components/organisms/CarouselModal';

interface ModalImageProps {
  listImage?: string[];
  isOpen: boolean;
  handleClose?: () => void
  activeIdx: number;
}

const ModalImage: React.FC<ModalImageProps> = ({
  listImage,
  isOpen,
  handleClose,
  activeIdx,
}) => {
  const [nav, setNav] = useState<Slider | null>();
  const [slideActive, setSlideActive] = useState(1);

  const settingModal = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    prevArrow: <PrevArrow customArrow="white" />,
    nextArrow: <NextArrow customArrow="white" />,
    infinite: false,
    cssEase: 'ease-in-out',
    afterChange: (next: number) => {
      setSlideActive(next + 1);
    },
  };
  useEffect(() => {
    if (nav) {
      setSlideActive(activeIdx + 1);
      nav?.slickGoTo(activeIdx);
    }
  }, [nav]);
  return (
    <CarouselModal
      isOpen={isOpen}
      handleClose={handleClose}
      showIconClose
    >
      <div className="t-modalImage">
        <Carousel settings={settingModal} ref={(slider) => setNav(slider)}>
          {
            listImage?.map((item, index) => (
              <div key={`carousel-image-${index.toString()}`} className="t-modalImage_item">
                <img src={item} alt={`shop-${index.toString()}`} />
              </div>
            ))
          }
        </Carousel>
        <div className="t-modalImage_slide">
          <Text modifiers={['16x24', '600', 'white']}>
            {slideActive}
            /
            {listImage?.length}
          </Text>
        </div>
      </div>
    </CarouselModal>
  );
};

export default ModalImage;
