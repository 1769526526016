import {
  IStoreSiteCheck,
  StoreSiteCheckParams,
  UpdateSiteCheckParams,
  CheckProjectParams,
  ProjectListTypes,
} from './types';

import axiosInstance, { axiosInstanceV2 } from 'services/common/instance';

export const postCheckProjectService = async (params: CheckProjectParams): Promise<void> => {
  const response = await axiosInstance.post('site-checks/projects', params);
  return response.data.data;
};

export const postStoreSiteCheckService = async (
  params: StoreSiteCheckParams
): Promise<IStoreSiteCheck> => {
  const response = await axiosInstance.post('site-checks', params);
  return response.data.data;
};

export const putUpdateSiteCheckService = async (
  code?: string,
  params?: UpdateSiteCheckParams
): Promise<void> => {
  const response = await axiosInstance.put(`site-checks/${code}`, params);
  return response.data.data;
};

export const getProjectList = async (): Promise<ProjectListTypes[]> => {
  const res = await axiosInstanceV2.get('projects/processing/all');
  return res.data.data;
};
